<script lang="ts" setup>
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { ImageFileField, SplitSectionRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: SplitSectionRecord; currentPageId: string }>();

const localePath = useLocalePath();

const items = computed(() => {
  return props.data.splitItems.map((i) => {
    const linkItem = getLinkForType(i.optLinkItem, localePath);

    return {
      id: i.id,
      title: i.optTitle,
      text: i.text,
      linkItem: linkItem,
      image: i.image,
      imagePosition: i.imagePosition,
    };
  });
});
</script>

<template>
  <div v-for="item in items" :key="item.id" class="full-bleed md:no-bleed">
    <div class="grid grid-cols-1 lg:grid-cols-2">
      <div
        :class="{ 'order-1 lg:order-1': item.imagePosition, 'order-1 lg:order-2': !item.imagePosition }"
        class="relative"
      >
        <!-- position: unset makes sure the image covers the whole parent element -->
        <DatocmsImage
          v-if="item.image as ImageFileField"
          :data="item.image?.responsiveImage as ResponsiveImageType"
          object-fit="cover"
          :fade-in-duration="0"
          :use-placeholder="false"
          style="position: unset"
        />

        <UButton
          v-if="item.linkItem.href"
          variant="solid"
          color="white"
          size="xl"
          :to="item.linkItem.href"
          :target="item.linkItem.target ?? '_self'"
          class="absolute bottom-7"
          :class="{ 'right-7': item.imagePosition, 'left-7': !item.imagePosition }"
        >
          {{ item.linkItem.label }}
        </UButton>
      </div>

      <div
        :class="{ 'order-2 lg:order-1': !item.imagePosition, 'order-2 lg:order-2': item.imagePosition }"
        class="flex items-center bg-gray-50 p-10 text-sm md:px-20"
      >
        <div class="html-content">
          <h2 v-if="item.title">
            {{ item.title }}
          </h2>

          <div v-html="item.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
