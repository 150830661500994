<script setup lang="ts">
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { DomainRecord } from "~/graphql/datocms/generated";

defineProps<{ data: DomainRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <NuxtLink
    :to="localePath({ name: 'domains-slug', params: { slug: data.slug } })"
    class="flex h-[150px] items-center justify-center border p-3 hover:shadow"
  >
    <DatocmsImage
      v-if="data.logo"
      :data="data.logo.responsiveImage as ResponsiveImageType"
      object-fit="contain"
      class="size-full"
    />
  </NuxtLink>
</template>

<style scoped>

</style>
