<script lang="ts" setup>
import type { AllGalleriesSectionRecord, GalleryRecord } from "~/graphql/datocms/generated";

defineProps<{ data: AllGalleriesSectionRecord; currentPageId: string }>();

const { data: galleriesData } = await useFetch("/api/cms/galleries", {
  deep: true,
});
</script>

<template>
  <div class="mb-16 mt-4">
    <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
      <GalleryCard v-for="gallery in galleriesData?.allGalleries" :key="gallery.id" :data="gallery as GalleryRecord" />
    </div>
  </div>
</template>

<style scoped>

</style>
