<script lang="ts" setup>
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { ArticleTeaserRecord } from "~/graphql/datocms/generated";

defineProps<{ data: ArticleTeaserRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <div class="stretched-link-card hover:shadow">
    <DatocmsImage v-if="data.image" :data="data.image.responsiveImage as ResponsiveImageType" object-fit="cover" />

    <div class="p-3">
      <h4 class="line-clamp-2 h-[55px]">
        {{ data.title }}
      </h4>

      <div class="html-content">
        <LinkCustom class="block py-2" :data="getLinkForType(data.linkItem, localePath)" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
