<script lang="ts" setup>
import type { ContactInfoCtaSectionRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: ContactInfoCtaSectionRecord; currentPageId: string }>();

const localePath = useLocalePath();
const ctaLink = computed(() => getLinkForType(props.data.cta, localePath));
</script>

<template>
  <div class="grid grid-cols-5 gap-6">
    <div class="col-span-5 lg:col-span-3">
      <FormContact :data="data.form" />
    </div>

    <div class="col-span-5 lg:col-span-2">
      <div class="flex h-full flex-col justify-center gap-5 px-0 py-4 text-sm leading-6 lg:px-10">
        <div>
          <StructuredContent :data="data.richText" />
        </div>

        <UButton variant="solid" color="primary" size="md" :to="ctaLink.href" :target="ctaLink.target ?? '_self'">
          {{ ctaLink.label }}
        </UButton>

        <div>
          <ClientOnly>
            <div v-html="data.googleMapsEmbed" />
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
