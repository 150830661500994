<script setup lang="ts">
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { GalleryRecord } from "~/graphql/datocms/generated";

defineProps<{ data: GalleryRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <div class="stretched-link-card border hover:shadow">
    <DatocmsImage
      v-if="data.optTeaserImage"
      :data="data.optTeaserImage.responsiveImage as ResponsiveImageType"
      object-fit="cover"
    />

    <div class="p-3">
      <h4 class="mb-md line-clamp-2 h-[55px]">
        {{ data.optTeaserTitle }}
      </h4>

      <div class="html-content">
        <NuxtLink
          :to="localePath({ name: 'galleries-slug', params: { slug: data.slug } })"
          class="text-primary text-sm underline"
        >
          {{ data.optTeaserLinkText }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
