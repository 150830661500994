<script lang="ts" setup>
import { z } from "zod";
import type { FormSubmitEvent } from "#ui/types";
import type { EngravingFormRecord } from "~/graphql/datocms/generated";
import type { FormState } from "~/models/forms";

const props = defineProps<{ data: EngravingFormRecord }>();

const { t } = useI18n();

const state = reactive({
  name: undefined,
  phone: undefined,
  address: undefined,
  postalCode: undefined,
  city: undefined,
  email: undefined,
  format: undefined,
  text: undefined,
  accept: undefined,
});

const formValidationAccept = t("form.validation.accept");
const formValidationEmail = t("form.validation.email");
const formValidationRequired = t("form.validation.required");

const schema = z.object({
  name: z.string({ required_error: formValidationRequired }),
  phone: z.string({ required_error: formValidationRequired }),
  address: z.string({ required_error: formValidationRequired }),
  postalCode: z.string({ required_error: formValidationRequired }),
  city: z.string({ required_error: formValidationRequired }),
  email: z.string({ required_error: formValidationRequired }).email(formValidationEmail),
  format: z.string({ required_error: formValidationRequired }),
  text: z.string({ required_error: formValidationRequired }),
  accept: z.boolean({ required_error: formValidationRequired }).refine(value => value === true, {
    message: formValidationAccept,
  }),
});

type Schema = z.output<typeof schema>;

const form = ref();
const formState = ref<FormState>("idle");

let recaptcha: IOurRecaptchaComposition | undefined;

async function onSubmit(event: FormSubmitEvent<Schema>) {
  // eslint-disable-next-line no-console
  console.log("engraving submit", event);

  try {
    formState.value = "pending";

    const recaptchaToken = recaptcha && await recaptcha.execute("engraving") || "";

    await $fetch("/api/engraving", {
      method: "POST",
      body: unref(state),
      headers: { "X-Recaptcha-Token": recaptchaToken },
    });

    formState.value = "success";
    document.body.scrollIntoView({ behavior: "smooth" });
  }
  catch (error) {
    console.error(error);

    formState.value = "error";
    useFailToast(props.data.exception.optTitle ?? "", props.data.exception.text);
  }
}

onMounted(async () => {
  recaptcha = await useVueRecaptcha();
});
</script>

<template>
  <UForm
    v-if="formState !== 'success'"
    id="engraving-form"
    ref="form"
    :schema="schema"
    :state="state"
    class="space-y-4"
    aria-live="assertive"
    role="form"
    @submit="onSubmit"
  >
    <h4>{{ data.title }}</h4>

    <UFormGroup :label="t('engraving.form.label.name')" name="name" class="required">
      <UInput v-model="state.name" autocomplete="name" spellcheck="false" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('engraving.form.label.phone')" name="phone" class="required">
      <UInput v-model="state.phone" autocomplete="tel" inputmode="tel" spellcheck="false" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('engraving.form.label.address')" name="address" class="required">
      <UInput v-model="state.address" autocomplete="street-address" spellcheck="false" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('engraving.form.label.postal-code')" name="postalCode" class="required">
      <UInput v-model="state.postalCode" autocomplete="postal-code" spellcheck="false" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('engraving.form.label.city')" name="city" class="required">
      <UInput v-model="state.city" autocomplete="address-level2" spellcheck="false" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('engraving.form.label.email')" name="email" class="required">
      <UInput
        v-model="state.email"
        type="email"
        autocomplete="email"
        inputmode="email"
        spellcheck="false"
        data-1p-ignore
      />
    </UFormGroup>

    <UFormGroup :label="t('engraving.form.label.format')" name="format" class="required">
      <UInput v-model="state.format" spellcheck="true" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('engraving.form.label.text')" name="text" class="required">
      <UTextarea v-model="state.text" spellcheck="true" data-1p-ignore />
    </UFormGroup>

    <UFormGroup name="accept">
      <div class="flex items-center gap-2">
        <UCheckbox id="engraving-accept" v-model="state.accept" />
        <label for="engraving-accept" class="html-label" v-html="data.acceptText" />
      </div>
    </UFormGroup>

    <UButton type="submit" variant="solid" color="primary" size="xl" :loading="formState === 'pending'">
      {{ $t("engraving.form.action.submit") }}
    </UButton>
  </UForm>

  <div v-if="formState === 'success'" class="html-content" v-html="data.confirmation.text" />
</template>

<style scoped>

</style>
