<script lang="ts" setup>
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { ImageFileField, UspSectionRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: UspSectionRecord; currentPageId: string }>();

const localePath = useLocalePath();

const items = computed(() => {
  return props.data.uspItems.map((i) => {
    const linkItem = getLinkForType(i.linkItem, localePath);
    return { id: i.id, title: i.title, linkItem: linkItem, image: i.image };
  });
});
</script>

<template>
  <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
    <div v-for="item in items" :key="item.id" class="usp-card">
      <DatocmsImage
        v-if="item.image as ImageFileField"
        :data="item.image?.responsiveImage as ResponsiveImageType"
        object-fit="cover"
      />

      <div class="absolute bottom-0 end-0 start-0 m-3 flex flex-col items-center bg-gray-50 p-2">
        <h3 class="mb-1 text-center">
          {{ item.title }}
        </h3>

        <NuxtLink
          v-if="item.linkItem.href"
          :to="item.linkItem.href"
          :target="item.linkItem.target"
          class="text-primary text-xs underline"
        >
          {{ item.linkItem.label }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
