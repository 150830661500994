<script lang="ts" setup>
import type { SwiperInfoCtaSectionRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: SwiperInfoCtaSectionRecord; currentPageId: string }>();

const localePath = useLocalePath();
const ctaLink = computed(() => getLinkForType(props.data.cta, localePath));

const swiperImages = computed(() => {
  return props.data.images.map((i) => {
    const linkItem = getLinkForType(i, localePath);
    return { id: i.id, image: i.image, linkItem: linkItem };
  });
});
</script>

<template>
  <div class="grid grid-cols-5 gap-6">
    <div class="col-span-5 lg:col-span-3">
      <SwiperImages
        :items="swiperImages"
        :slides-per-view="1"
        slide-aspect-ratio="auto"
        navigation-position="bottom-right"
      />
    </div>

    <div class="col-span-5 lg:col-span-2">
      <div class="flex h-full flex-col justify-center gap-5 bg-gray-50 px-10 py-4 text-sm leading-6">
        <div>
          <StructuredContent :data="data.richText" />
        </div>

        <UButton variant="solid" color="primary" size="md" :to="ctaLink.href" :target="ctaLink.target ?? '_self'">
          {{ ctaLink.label }}
        </UButton>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
