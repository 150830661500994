<script lang="ts" setup>
// https://swiperjs.com/element (NOTE: do not forget to add Swiper web components in nuxt.config.ts)
import type { ProductListItem } from "~/models/products";
import type { NavigationPosition } from "~/models/swiper";

const props = defineProps<{
  products?: Array<ProductListItem>;
  slidesPerView: number;
  navigationPosition: NavigationPosition;
}>();

const { trackCustomEvent } = useGtmTracking();
const { locale } = useI18n();
const localePath = useLocalePath();

let currentSlidesPerView = props.slidesPerView;
let currentBreakpoints = {};

if (props.slidesPerView > 1) {
  currentSlidesPerView = 1;

  currentBreakpoints = {
    640: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1440: {
      slidesPerView: props.slidesPerView,
    },
  };
}

async function onSelectProduct(product: ProductListItem) {
  trackCustomEvent("product-swiper-select");
  await navigateTo(localePath(`${product.clusterUrl}`));
}
</script>

<template>
  <swiper-container
    :slides-per-view="currentSlidesPerView"
    :breakpoints="currentBreakpoints"
    :space-between="16"
    :centered-slides="false"
    :navigation="{}"
    :loop="true"
    :class="`swiper__navigation-position--${navigationPosition}`"
  >
    <swiper-slide v-for="product in products" :key="product.id">
      <ProductCard
        :product="product"
        :locale="locale"
        @select="onSelectProduct"
      />
    </swiper-slide>
  </swiper-container>
</template>

<style scoped>

</style>
