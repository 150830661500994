<script lang="ts" setup>
import { z } from "zod";
import type { ContactFormRecord } from "~/graphql/datocms/generated";
import type { FormState } from "~/models/forms";

const props = defineProps<{ data: ContactFormRecord }>();

const { t } = useI18n();
const { query } = useRoute();

const state = reactive({
  name: undefined,
  email: undefined,
  phone: undefined,
  city: undefined,
  country: undefined,
  message: undefined,
  accept: undefined,
});

const countryList = props.data.countries.map(c => c.name);

const formValidationAccept = t("form.validation.accept");
const formValidationEmail = t("form.validation.email");
const formValidationRequired = t("form.validation.required");

const schema = z.object({
  name: z.string({ required_error: formValidationRequired }),
  email: z.string({ required_error: formValidationRequired }).email(formValidationEmail),
  phone: z.string({ required_error: formValidationRequired }),
  city: z.string({ required_error: formValidationRequired }),
  country: z.string({ required_error: formValidationRequired }),
  message: z.string({ required_error: formValidationRequired }),
  accept: z.boolean({ required_error: formValidationRequired }).refine(value => value === true, {
    message: formValidationAccept,
  }),
});

const form = ref();
const formState = ref<FormState>("idle");

let recaptcha: IOurRecaptchaComposition | undefined;

async function onSubmit() {
  try {
    formState.value = "pending";

    const recaptchaToken = recaptcha && await recaptcha.execute("contact") || "";

    await $fetch("/api/contact", {
      method: "POST",
      body: {
        ...unref(state),
        subject: query.sku && query.name
          ? t("contact.form.subject.product-request", { SKU: query.sku, Name: query.name })
          : undefined,
      },
      headers: { "X-Recaptcha-Token": recaptchaToken },
    });

    formState.value = "success";
    document.body.scrollIntoView({ behavior: "smooth" });
  }
  catch (error) {
    console.error(error);

    formState.value = "error";
    useFailToast(props.data.exception.optTitle ?? "", props.data.exception.text);
  }
}

onMounted(async () => {
  recaptcha = await useVueRecaptcha();
});
</script>

<template>
  <UForm
    v-if="formState !== 'success'"
    id="contact-form"
    ref="form"
    :schema="schema"
    :state="state"
    class="space-y-4"
    aria-live="assertive"
    role="form"
    @submit="onSubmit"
  >
    <UFormGroup :label="t('contact.form.label.name')" name="name" class="required">
      <UInput v-model="state.name" autocomplete="name" spellcheck="false" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('contact.form.label.email')" name="email" class="required">
      <UInput
        v-model="state.email"
        type="email"
        autocomplete="email"
        inputmode="email"
        spellcheck="false"
        data-1p-ignore
      />
    </UFormGroup>

    <UFormGroup :label="t('contact.form.label.phone')" name="phone" class="required">
      <UInput v-model="state.phone" autocomplete="tel" inputmode="tel" spellcheck="false" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('contact.form.label.city')" name="city" class="required">
      <UInput v-model="state.city" autocomplete="address-level2" spellcheck="false" data-1p-ignore />
    </UFormGroup>

    <UFormGroup :label="t('contact.form.label.country')" name="country" class="required">
      <USelectMenu
        v-model="state.country" :options="countryList"
        :placeholder="t('contact.form.placeholder.country')"
      />
    </UFormGroup>

    <UFormGroup :label="t('contact.form.label.message')" name="message" class="required">
      <UTextarea v-model="state.message" spellcheck="true" data-1p-ignore />
    </UFormGroup>

    <UFormGroup name="accept">
      <div class="flex items-center gap-2">
        <UCheckbox id="contact-accept" v-model="state.accept" />
        <label for="contact-accept" class="html-label text-sm" v-html="data.acceptText" />
      </div>
    </UFormGroup>

    <UButton type="submit" variant="solid" color="primary" size="xl" :loading="formState === 'pending'">
      {{ $t("contact.form.action.submit") }}
    </UButton>
  </UForm>

  <div v-if="formState === 'success'" class="html-content" v-html="data.confirmation.text" />
</template>

<style scoped>

</style>
