<script lang="ts" setup>
import type { GalleryRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: GalleryRecord }>();

const swiperImages = computed(() => {
  return props.data.images.map((i) => {
    return { id: i.id, image: i };
  });
});
</script>

<template>
  <Fancybox v-if="data.displayType" :options="{}">
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
      <a v-for="image in data.images" :key="image.id" :href="image.responsiveImage.src" data-fancybox="gallery">
        <NuxtImg
          :src="(image.responsiveImage.src as string)"
          alt=""
          provider="imgix"
          fit="crop"
          width="400"
          height="400"
          :modifiers="{ auto: 'compress' }"
        />
      </a>
    </div>
  </Fancybox>

  <SwiperImages
    v-if="!data.displayType"
    :items="swiperImages"
    :slides-per-view="4"
    slide-aspect-ratio="16-9"
    navigation-position="bottom-right"
  />
</template>

<style scoped>

</style>
